<script lang="ts">
	import { stopPropagation } from '$lib/utils/clickHelpers'
	import { override } from '$src/lib/utils/classnames'

	interface Props {
		title: string
		disabled?: boolean
		class?: string
		children?: import('svelte').Snippet
		onclick?: () => void
	}

	let { title, disabled = false, children, onclick, class: className = '' }: Props = $props()

	let buttonClass = override(
		'w-10 h-10 bg-black text-white rounded-full flex items-center justify-center shadow-md active:opacity-80 disabled:opacity-50 transition-all duration-200 hover:bg-brand-gray-5 transform hover:scale-105 active:scale-105 touch-manipulation',
		className,
	)

	function handleClick() {
		onclick?.()
	}
</script>

<button {disabled} onclick={stopPropagation(handleClick)} class={buttonClass} {title}>
	{@render children?.()}
</button>
