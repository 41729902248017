<!-- Portal.svelte -->
<script lang="ts" module>
	export function moveToPortal(
		node: HTMLElement,
		{ target, anchor }: { target: HTMLElement; anchor: HTMLElement | null },
	) {
		// Get all menu items and transform into a floating menu
		const menuItemsContainer = node.querySelector('[role="menu"]')?.parentElement
		if (!menuItemsContainer) return

		function updatePosition() {
			if (!anchor) return

			const rect = anchor.getBoundingClientRect()
			const menuRect = menuItemsContainer.getBoundingClientRect()

			// Position the menu above the avatar, aligned to the left
			const left = Math.max(rect.left, 10) // Prevent menu from going off-screen left
			const bottom = window.innerHeight - rect.top + 8 // 8px gap above avatar

			// Ensure menu doesn't go off-screen right
			const rightOverflow = left + menuRect.width - window.innerWidth
			const finalLeft = rightOverflow > 0 ? left - rightOverflow - 10 : left

			menuItemsContainer.style.position = 'fixed'
			menuItemsContainer.style.left = `${finalLeft}px`
			menuItemsContainer.style.bottom = `${bottom}px`
			menuItemsContainer.style.width = '54px' // w-48
			menuItemsContainer.style.transform = 'none' // Reset any existing transforms
		}

		// Initial position
		updatePosition()

		// Update position on scroll and resize
		window.addEventListener('scroll', updatePosition)
		window.addEventListener('resize', updatePosition)

		return {
			destroy() {
				window.removeEventListener('scroll', updatePosition)
				window.removeEventListener('resize', updatePosition)
				if (node.parentNode) {
					node.parentNode.removeChild(node)
				}
			},
		}
	}
</script>

<script lang="ts">
	import { onMount, type Snippet } from 'svelte'

	interface Props {
		children?: Snippet
		targetId?: string
		anchor?: HTMLElement | null
	}

	let { children, targetId = 'my-portal-root', anchor = null }: Props = $props()

	let portalContainer: HTMLElement | null = $state(null)

	onMount(() => {
		let container = document.getElementById(targetId)
		if (!container) {
			container = document.createElement('div')
			container.id = targetId
			container.style.position = 'fixed'
			container.style.zIndex = '50'
			container.style.top = '0'
			container.style.left = '0'
			container.style.width = '100%'
			container.style.height = '100%'
			container.style.pointerEvents = 'none'
			document.body.appendChild(container)
		}
		portalContainer = container

		return () => {
			if (container && container.childNodes.length === 0) {
				container.parentNode?.removeChild(container)
			}
		}
	})
</script>

{#if portalContainer}
	<div use:moveToPortal={{ target: portalContainer, anchor }} style="pointer-events: auto;">
		{@render children?.()}
	</div>
{/if}
