<script lang="ts">
	import { isOpen } from '$lib/stores/menu-store'
	import { override } from '$lib/utils/classnames'
	import { stopPropagation } from '$lib/utils/clickHelpers'
	import { twMerge } from 'tailwind-merge'

	interface Props {
		label?: string
		class?: string
		children?: import('svelte').Snippet
		onclick?: () => void
	}

	let { label = '', class: className = '', children, onclick }: Props = $props()

	const sharedClasses =
		'flex items-center px-4 py-2 w-full cursor-pointer bg-transparent focus:outline-none text-sm space-x-4 group transition-all font-medium text-black hover:bg-brand-gray-2 dark:text-white dark:hover:bg-brand-gray-4 dark:hover:bg-opacity-20 touch-manipulation'

	let computedClassName = $derived(twMerge(override(sharedClasses, className)))

	function handleClick() {
		$isOpen = false // Close menu when item is clicked
		onclick?.()
	}
</script>

<button
	class={computedClassName}
	onclick={stopPropagation(handleClick)}
	role="menuitem"
	type="button"
>
	{@render children?.()}
	<div class="leading-4">{label}</div>
</button>
