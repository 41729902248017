<script lang="ts">
	import { scale } from 'svelte/transition'
	import { isOpen } from '$lib/stores/menu-store'
	import Portal from './Portal.svelte'

	interface Props {
		class?: string
		children?: import('svelte').Snippet
	}

	let { class: className = '', children }: Props = $props()
	let buttonElement: HTMLElement | null = $state(null)
</script>

<div bind:this={buttonElement}>
	{#if $isOpen}
		<Portal anchor={buttonElement}>
			<div
				in:scale={{ duration: 200, start: 0.95, opacity: 1 }}
				out:scale={{ duration: 150, start: 0.95, opacity: 0 }}
			>
				{@render children?.()}
			</div>
		</Portal>
	{/if}
</div>
