<script lang="ts">
	import { setContext } from 'svelte'
	import { isOpen } from '$lib/stores/menu-store'

	interface Props {
		class?: string
		children?: import('svelte').Snippet
	}

	let { class: className = '', children }: Props = $props()

	setContext('menu', isOpen)
</script>

<div class={className}>
	{@render children?.()}
</div>
