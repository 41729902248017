<script lang="ts">
	import { page } from '$app/state'
	import { fade } from 'svelte/transition'
	import FullLogoIcon from '../icons/FullLogoIcon.svelte'
	import { stopPropagation } from '$lib/utils/clickHelpers'
	import type { Snippet } from 'svelte'
	import RoundIconButton from '$components/buttons/RoundIconButton.svelte'
	import ExIcon from '$components/icons/ExIcon.svelte'

	let isBannerBlocked = $derived(page.data.blockBanner)

	interface Props {
		isOpen?: boolean
		children?: Snippet
		onclose?: () => void
	}

	let { isOpen = false, children, onclose }: Props = $props()

	function closeMenu() {
		onclose?.()
	}
</script>

{#if isOpen}
	<div class="relative z-50 w-full">
		<div transition:fade|local={{ duration: 100 }} class="absolute inset-0">
			<button
				class="fixed inset-0 bg-black overscroll-contain transform-gpu bg-opacity-20 backdrop-blur-sm backdrop-filter"
				onclick={stopPropagation(closeMenu)}
			>
				<!-- Save space -->
			</button>
		</div>

		<div
			class={`${
				!isBannerBlocked ? 'top-16 md:top-12' : 'top-0'
			} fixed inset-0 w-full h-auto pointer-events-none`}
		>
			<div
				class="pt-4 pb-6 bg-white w-full dark:bg-brand-gray-5 dark:text-white rounded-b-lg shadow-lg ring-1 ring-black ring-opacity-5 pointer-events-auto"
			>
				<div class="flex items-center justify-between px-5 w-full">
					<a href="/" class="flex focus:outline-none focus:ring-none">
						<FullLogoIcon height={'32'} width={'128'} />
						<span class="sr-only">Go to the airheart home page</span>
					</a>

					<RoundIconButton
						title="Close Panel"
						onclick={closeMenu}
						class="text-black bg-white hover:dark:bg-brand-gray-2"
					>
						<ExIcon class="h-4 w-4 m-auto text-black dark:text-black" />
					</RoundIconButton>
				</div>
				<div class="px-2 mt-6 divide-y divide-brand-gray-4 dark:divide-brand-gray-2 w-full">
					{@render children?.()}
				</div>
			</div>
		</div>
	</div>
{/if}
