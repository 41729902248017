<script lang="ts">
	interface Props {
		height?: string; // Default height
		width?: string; // Default width
		class?: string;
	}

	let { height = '48px', width = '48px', class: className = '' }: Props = $props();
	
</script>

<svg
	xmlns="http://www.w3.org/2000/svg"
	fill="none"
	viewBox="0 0 48 48"
	{height}
	{width}
	class={className}
	><g clip-path="url(#a)"
		><path
			fill="currentColor"
			fill-rule="evenodd"
			d="M0 2c0 -1.10457 0.89543 -2 2 -2h36c1.1046 0 2 0.89543 2 2v10H20c-2.2 0 -4 1.8 -4 4v12h-1.17158l-7.4142 7.4142c-0.572 0.572 -1.43224 0.7432 -2.17958 0.4336C4.48728 35.5382 4 34.809 4 34v-6H2c-1.10457 0 -2 -0.8954 -2 -2V2Z"
			clip-rule="evenodd"
			stroke-width="2"
		/><path
			fill="currentColor"
			fill-rule="evenodd"
			d="M22 16c-1.1046 0 -2 0.89544 -2 2v20c0 1.1046 0.8954 2 2 2h11.1716l7.4142 7.4142c0.572 0.572 1.4322 0.7432 2.1796 0.4336 0.7474 -0.3096 1.2346 -1.0388 1.2346 -1.8478v-6h2c1.1046 0 2 -0.8954 2 -2V18c0 -1.10456 -0.8954 -2 -2 -2H22Z"
			clip-rule="evenodd"
			stroke-width="2"
		/></g
	><defs
		><clipPath id="a"><path fill="currentColor" d="M0 0h48v48H0z" stroke-width="2" /></clipPath
		></defs
	></svg
>
