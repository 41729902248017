<script lang="ts">
	interface Props {
		height?: string; // Default height
		width?: string; // Default width
		class?: string;
	}

	let { height = '48px', width = '48px', class: className = '' }: Props = $props();
	
</script>

<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {height} {width} class={className}
	><path
		d="m45.838 21.212 -6 -14A2 2 0 0 0 38 6H10a2 2 0 0 0 -1.838 1.212l-6 14A2 2 0 0 0 4 24h2v16a2 2 0 0 0 2 2h20v-14h8v14h4a2 2 0 0 0 2 -2v-16h2a2 2 0 0 0 1.838 -2.788zM22 36H12v-8h10zm18.966 -16h-5.458l-2.856 -10h4.03zM16.652 20l2.856 -10H22v10zM26 10h2.492l2.856 10H26zM11.318 10h4.03l-2.856 10H7.034z"
		fill="currentColor"
		stroke-width="1"
	/></svg
>
