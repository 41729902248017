<script lang="ts">
	import { isOpen } from '$lib/stores/menu-store'
	import { stopPropagation } from '$lib/utils/clickHelpers'

	interface Props {
		class?: string
		children?: import('svelte').Snippet
	}

	let { class: className = '', children }: Props = $props()

	let buttonRef: HTMLButtonElement = $state()

	function handleClick() {
		isOpen.set(!$isOpen)
	}
</script>

<button
	bind:this={buttonRef}
	onclick={stopPropagation(handleClick)}
	class={`flex items-center m-0 text-right flex-shrink-0 outline-none cursor-pointer select-none rounded-md focus:outline-none touch-manipulation ${className}`}
	aria-expanded={$isOpen}
	aria-haspopup="true"
>
	{@render children?.()}
</button>
